import './Contact.css'

const Contact = () => {
  return (
    <div id="contact-main">
      <h1 id="contact-h1-email"><a href="mailto:nicholaspriegel@gmail.com">nicholaspriegel@gmail.com</a></h1>
    </div>
  )
}

export default Contact
